import HomeView from "../views/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    props: { default: true },
  },
  {
    path: "/login/",
    name: "login",
    component: () => import("@/views/user/LoginPage.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/logout/",
    name: "logout",
    component: () => import("@/views/user/LogoutPage.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
  {
    path: "/statement",
    name: "statement",
    component: () => import("@/views/PensionStatement.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("@/views/PaymentsView.vue"),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
    },
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("@/views/JobsView.vue"),
    meta: {
      public: false, // Allow access to even if not logged in
      onlyWhenLoggedOut: false,
    },
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("@/components/PdfGenerator/PensionPdfContent.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  // {
  //   path: "/register/",
  //   name: "register",
  //   component: () => import("@/views/user/RegisterPage.vue"),
  //   meta: {
  //     public: true, // Allow access to even if not logged in
  //     onlyWhenLoggedOut: true,
  //   },
  // },
  // {
  //   path: "/reset-password/:token",
  //   name: "resetPassword",
  //   component: () => import("@/views/user/ResetPassword.vue.old"),
  //   meta: {
  //     public: true, // Allow access to even if not logged in
  //     onlyWhenLoggedOut: true,
  //   },
  // },
  // {
  //   path: "/first-login/:token",
  //   name: "firstLogin",
  //   component: () => import("@/views/user/FirstLogin.vue"),
  //   meta: {
  //     public: true, // Allow access to even if not logged in
  //     onlyWhenLoggedOut: true,
  //   },
  // },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/user/ForgotPassword"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/account-forgot-password",
    name: "accountForgotPassword",
    component: () => import("@/views/user/AccountForgotPassword"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: () => import("@/views/user/ResetPassword"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/user/ErrorPage"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/forgot-email",
    name: "forgotEmail",
    component: () => import("@/views/user/ForgotEmail"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/forgot-username",
    name: "forgotUsername",
    component: () => import("@/views/user/ForgotUsername"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/member-information",
    name: "memberInformation",
    component: () => import("@/views/MemberInformation"),
  },
  {
    path: "/member-registration",
    name: "memberRegistration",
    component: () => import("@/views/MemberRegistration"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/member-validation-complete",
    name: "memberValidationComplete",
    component: () => import("@/views/MemberValidationComplete"),
    meta: {
      public: true, // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: "/member-registration-completed",
    name: "memberRegistrationCompleted",
    component: () => import("@/views/MemberRegistrationCompleted"),
  },
  {
    path: "/registration-completion",
    name: "memberRegistrationCompletion",
    component: () => import("@/views/MemberRegistrationCompletion"),
  },
  {
    path: "/beneficiary-centre",
    name: "beneficiaryCentre",
    component: () => import("@/views/BeneficiaryCentre"),
  },
  // {
  //   path: "/html-editor-free",
  //   name: "HtmlEditorFree",
  //   component: () => import("@/views/HtmlEditorFree"),
  // },
  {
    path: "/pension-earned-to-date",
    name: "pensionEarnedToDate",
    component: () => import("@/views/PensionEarnedToDate"),
    meta: {
      onlyIfAllowedToMember: true,
    },
  },
  {
    path: "/balance",
    name: "MyBalance",
    component: () => import("@/views/MyBalance"),
  },
  // {
  //   path: "/html-editor",
  //   name: "HtmlEditor",
  //   component: () => import("@/views/HtmlEditor"),
  // },
  {
    path: "/claim-centre",
    name: "claimCentre",
    component: () => import("@/views/ClaimCentre"),
    children: [
      {
        path: "/submit-claim",
        name: "submitClaim",
        component: () =>
          import("@/components/ClaimCentre/ClaimSubmissionComponent.vue"),
      },
      {
        path: "/claims-history",
        name: "claimsHistory",
        component: () =>
          import("@/components/ClaimCentre/ClaimsHistoryComponent"),
      },
      {
        path: "/benefits-balance",
        name: "benefitsBalance",
        props: true,
        component: () =>
          import(
            "@/components/ClaimCentre/BenefitBalances/BenefitBalancesComponent"
          ),
      },
      {
        path: "/benefits-balance-legacy",
        name: "benefitsBalanceLegacy",
        props: true,
        component: () =>
          import(
            "@/components/ClaimCentre/BenefitBalancesLegacy/BenefitBalancesComponentLegacy"
          ),
      },
      {
        path: "/sick-pay",
        name: "sickPay",
        props: true,
        component: () =>
          import("@/components/ClaimCentre/SickPay/SickPayComponent"),
      },
      {
        path: "/hsa-balance",
        name: "hsaBalance",
        props: true,
        component: () => import("@/components/ClaimCentre/HSA/HsaComponent"),
      },
    ],
  },
  {
    path: "/downloads",
    name: "downloads",
    component: () => import("@/views/DownloadsBenefitCards"),
    props: true,
    children: [
      {
        path: "/download-centre",
        name: "downloadCentre",
        component: () => import("@/views/DownloadCentre"),
        props: true,
      },
      {
        path: "/benefit-cards",
        name: "benefitCards",
        component: () =>
          import("@/components/BenefitCard/BenefitCardsComponent"),
      },
    ],
  },
  {
    path: "/download-centre-new",
    name: "downloadCentreNew",
    component: () => import("@/views/DownloadCentreNew"),
    props: true,
  },
  {
    path: "/drug-provider-search",
    name: "drugProviderSearch",
    component: () => import("@/views/DrugProviderSearch"),
    children: [
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/FaqView"),
      },
      {
        path: "/provider-search",
        name: "providerSearch",
        component: () => import("@/views/ProviderSearch"),
      },
      {
        path: "/drug-search",
        name: "drugSearch",
        component: () => import("@/views/DrugSearch"),
      },
    ],
  },
  // {
  //   path: "/provider-search",
  //   name: "providerSearch",
  //   component: () => import("@/views/ProviderSearch"),
  // },
  // {
  //   path: "/drug-search",
  //   name: "drugSearch",
  //   component: () => import("@/views/DrugSearch"),
  // },
  {
    path: "/life-events",
    name: "lifeEvents",
    component: () => import("@/views/LifeEvents"),
    props: { lifeEvent: false },
  },
  {
    path: "/life-event/:tab/category/:categoryId/question/:questionId",
    name: "lifeEvent",
    component: () => import("@/views/LifeEventDetail.vue"),
  },
  {
    path: "/preferred-providers",
    name: "preferredProviders",
    component: () => import("@/views/PreferredProviders"),
    props: { preferredProvider: false },
  },
  {
    path: "/preferred-provider/category/:categoryId/provider/:providerId",
    name: "preferredProvider",
    component: () => import("@/views/PreferredProviderDetail.vue"),
  },
  {
    path: "/settings-account",
    name: "settingsAccount",
    component: () => import("@/views/SettingsAccount.vue"),
    meta: {},
  },
  {
    path: "/terms-conditions",
    name: "termsConditions",
    component: () => import("@/views/TermsConditions"),
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () => import("@/views/PrivacyPolicy"),
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () => import("@/views/AccessibilityPage"),
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactView.vue"),
    meta: {
      public: true, // Allow access to even if not logged in
    },
  },
];
export default routes;
