<template>
  <MaterialCardSba :height="3" color="primary" title="Member Balance">
    <v-card-text style="display: flex; flex-direction: column; height: 90%">
      <h1 style="margin-bottom: 2rem; margin-top: 7rem">Current Balance</h1>
      <h2
        :style="{
          fontSize: '2rem',
          color: `${$vuetify.theme.themes.light.primary}`,
        }"
      >
        ${{ balance.toFixed(2) }}
      </h2>

      <v-btn
        style="margin-top: auto; margin-left: auto; margin-right: 1rem"
        color="primary"
        @click="$router.push('/payments')"
        >SEE DETAILS</v-btn
      >
    </v-card-text>
  </MaterialCardSba>
</template>

<script>
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";

export default {
  components: {
    MaterialCardSba,
  },
  props: {
    balance: {
      type: Number,
      default: 100,
    },
  },
};
</script>
