<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :widgetIcon="widgetIcon"
    color="primary"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :widgetId="widgetId"
    :title="
      $t(
        `components.vue_material_dashboard.items.member_information.my_profile`
      )
    "
  >
    <div
      style="height: 100%; width: 100%; display: flex; flex-direction: column"
    >
      <v-progress-circular
        class="mt-16"
        size="50"
        v-if="loading"
        color="primary"
        indeterminate
      />
      <v-card
        flat
        height="58"
        width="100%"
        color="primary"
        class="white--text rounded-0"
      >
        <v-row no-gutters justify="end" class="fill-height">
          <v-col
            align-self="end"
            cols="9"
            sm="7"
            md="9"
            :class="`
            text-subtitle-1
            pa-0 pl-2 pb-2
            name-title
            float-right
            font-weight-medium`"
          >
            {{
              $helpers.capitalize(
                internalMember.FirstName + " " + internalMember.LastName
              )
            }}
          </v-col>
        </v-row>
      </v-card>
      <v-card flat class="white secondary--text rounded-t-0">
        <v-row no-gutters justify="end" class="mt-2 mr-6 mr-xl-12 pr-xl-6">
          <v-col
            v-if="internalMember.RateCode"
            cols="12"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label">Rate Code:</span>
            <strong class="font-weight-medium">
              {{ internalMember.RateCode.Name }}
            </strong>
          </v-col>
          <v-col
            v-if="internalMember.Phone"
            cols="12"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label">Phone:</span>
            <strong class="font-weight-medium">
              {{ internalMember.Phone }}
            </strong>
          </v-col>
          <v-col
            v-if="internalMember.Email"
            cols="12"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label">Email:</span>
            <strong class="font-weight-medium">
              {{ internalMember.Email }}
            </strong>
          </v-col>
          <v-col
            v-if="internalMember.Address"
            cols="12"
            style="max-width: 200px"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label">Address:</span>
            <strong class="font-weight-medium">
              {{ formatAddress }}
            </strong>
          </v-col>
          <v-col
            v-if="internalMember.HomeLocal"
            cols="10"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label"> Home Local </span>
            <strong class="font-weight-medium">
              {{ internalMember.HomeLocal && internalMember.HomeLocal.Name }}
            </strong>
          </v-col>
          <v-col
            v-if="userProfile.Dob"
            cols="10"
            class="text_title pa-0 font-weight-light text-right"
          >
            <span class="text_label">
              {{
                $t(
                  "components.vue_material_dashboard.items.member_information.dob"
                )
              }}:
            </span>
            <strong class="font-weight-medium">
              {{ $helpers.formatDate(userProfile.Dob) }}
            </strong>
          </v-col>
        </v-row>
      </v-card>
      <v-avatar
        class="avatar"
        :size="
          $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.smAndDown ? 60 : 80
        "
        v-show="internalMember.FullName"
        color="white"
      >
        <img
          v-if="hasImage"
          :src="'data:image/jpg;base64,' + internalMember.Image"
          alt="member-img"
        />
        <v-avatar v-else color="secondary" size="78">
          <span class="text-h5 white--text">
            {{
              internalMember.FullName &&
              internalMember.FullName.charAt(0) +
                (internalMember.LastName && internalMember.LastName.charAt(0))
            }}
          </span>
        </v-avatar>
      </v-avatar>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: start;
          padding-left: 2rem;
        "
      >
        <strong style="margin-top: 1rem">Safety Tickets</strong>
        <p style="font-size: 12px">{{ safetyTicketList }}</p>
        <strong style="margin-top: 0.5rem">Qualifications</strong>
        <div
          id="qualifications-list"
          style="height: 100%; max-height: 200px; overflow: auto; width: 100%"
        >
          <v-list style="text-align: left">
            <v-list-item v-for="mq in qualifications" :key="mq.Id">
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark> mdi-certificate</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ mq.Qualification.Name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ mq.Level.Name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- hello -->
        </div>
      </div>

      <v-btn
        color="primary"
        style="
          margin-top: auto;
          margin-bottom: 4rem;
          margin-left: auto;
          margin-right: 1rem;
        "
        @click="$router.push('/member-information')"
        >Update</v-btn
      >
    </div>
  </material-card-sba>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service";

export default {
  components: {
    MaterialCardSba,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      safetyTickets: [],
      qualifications: [],
      items: [],
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      loading: true,
      beneficiariesData: [],
      hasImage: true,
      coverages: [],
      internalMember: {
        Beneficiaries: [],
        Dependants: [],
        Coverages: [],
        Image: null,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "userProfile",
      "certificateLabel",
      "vuetifyTheme",
    ]),

    safetyTicketList() {
      return this.items.map((x) => x.SafetyTicket.Name).join(", ");
    },

    formatAddress() {
      const addr = this.internalMember.Address;

      return [
        addr.StreetNumber,
        addr.SuiteNumber,
        addr.StreetName,
        addr.City,
        addr.ProvinceState.Name,
        addr.PostalCode,
      ]
        .filter((x) => !!x)
        .join(" ");
    },

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    headers() {
      return [
        // {
        //   text: "Id",
        //   sortable: false,
        //   value: "Id",
        //   class: "secondary--text text-uppercase pr-0 pl-2",
        //   cellClass: "pl-2 pr-0",
        // },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.name"
          ),
          align: "center",
          sortable: false,
          value: "FirstName",
          class: "secondary--text px-0",
          cellClass: "px-2",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.dob_abvr"
          ),
          align: "center",
          sortable: false,
          value: "DateOfBirth",
          class: "secondary--text text-uppercase px-0",
          cellClass: "px-0",
        },
        // {
        //   text: this.$t(
        //     "components.vue_material_dashboard.items.member_information.relationship"
        //   ),
        //   align: "center",
        //   sortable: false,
        //   value: "Relationship",
        //   class: "secondary--text px-0",
        //   cellClass: "px-2",
        //   width: "20%",
        // },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.eligible"
          ),
          align: "center",
          sortable: false,
          value: "Eligible",
          class: "secondary--text px-0",
          cellClass: "px-2",
        },
      ];
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v !== null && v !== undefined) {
          this.internalMember = JSON.parse(JSON.stringify(this.userProfile));
          this.beneficiariesData = this.internalMember.Beneficiaries;
          this.hasImage =
            this.internalMember && this.internalMember.Image ? true : false;
          this.coverages = this.internalMember.Coverages;
          this.coverages?.sort((a, b) => {
            if (a.CurrentBenefit.Order < b.CurrentBenefit.Order) {
              return -1;
            }
            if (a.CurrentBenefit.Order > b.CurrentBenefit.Order) {
              return 1;
            }
            return 0;
          });
          this.loading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
      immediate: true,
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },

  mounted() {
    this.loadSafetyTickets();
    this.loadQualifications();
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }

    this.internalHeight = this.height;
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("data", ["getMemberData"]),
    async loadQualifications() {
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        const resp = await ApiService.get(`${url}qualifications`);
        this.qualifications = resp.data;
      } finally {
        this.loading = false;
      }
    },

    async loadSafetyTickets() {
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;
        const resp = await ApiService.get(`${url}tickets`);
        const tickets = await ApiService.get(`${url}safety-tickets`);

        this.items = resp.data;
        this.safetyTickets = tickets.data;
      } finally {
        this.loading = false;
      }
    },

    formatCoverage(coverages) {
      const resp = [];
      for (const coverage of coverages) {
        let str = this.$helpers.translate2(
          coverage.CurrentBenefit,
          "Name",
          this.$vuetify.lang.current
        );
        if (coverage.CoverageStatusId) {
          str += ` - ${this.$helpers.formatEnumModel(
            coverage.CoverageStatusId,
            "FamilyStatus",
            this.$vuetify.lang.current
          )}`;
          const coverageStatusName = this.$helpers.formatEnumModel(
            coverage.CoverageStatusId,
            "FamilyStatus",
            "en"
          );
          if (
            coverageStatusName == "Family" ||
            coverageStatusName == "Single Parent"
          ) {
            if (
              coverage.NoOfDependants !== null &&
              coverage.NoOfDependants !== undefined
            ) {
              str += ` (${coverage.NoOfDependants})`;
            }
          }
        }
        if (coverage.Volume) {
          str += ` (${coverage.Volume})`;
        }
        resp.push(str);
      }
      return resp.join(", ");
    },
  },
};
</script>
<style></style>

<style scoped>
.avatar {
  position: absolute;
  top: 60px;
  left: 20px;
  border: 3px white solid;
  z-index: 1;
}

.card-link {
  cursor: pointer;
}

.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

#qualifications-list::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}

#qualifications-list::-webkit-scrollbar-track-piece {
  background-color: white;
  border-color: white;
  border-width: 2px 7px 2px 7px;
  border-style: solid;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#qualifications-list::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--primary) !important;
  border-color: white;
  border-style: solid;
  border-width: 1px 4px 1px 4px;
}

.v-application .text-caption {
  font-weight: 600;
  line-height: 0.75rem !important;
  letter-spacing: 0.01em !important;
  font-size: 0.7rem !important;
}
.table >>> .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.6rem !important;
  font-weight: 600;
  height: 40px;
}
.table >>> .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.75rem !important;
  font-weight: 600;
  height: 35px;
  box-shadow: inset 0 1px 0 rgb(0 0 0 / 12%), inset 0 -1px 0 rgb(0 0 0 / 12%);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-track-piece {
  background-color: white;
  border-color: white;
  border-width: 2px 7px 2px 7px;
  border-style: solid;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--primary) !important;
  border-color: white;
  border-style: solid;
  border-width: 1px 4px 1px 4px;
}
.name-title {
  word-break: keep-all !important;
}
.relationship {
  font-size: 8px;
}
.text_title {
  font-size: 0.875rem;
}
.text_subtitle {
  font-size: 0.75rem;
}
.text_label {
  font-size: 0.8rem;
}
</style>
