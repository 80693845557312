var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"pa-2 pa-sm-4 pa-lg-8 my-0 my-sm-5 pt-sm-4 rounded-lg bg-white",staticStyle:{"box-shadow":"none"}},[(_vm.contactUsNote)?_c('v-card-title',{class:`
                    text-subtitle-2
                    font-weight-medium pt-0`,domProps:{"innerHTML":_vm._s(
          _vm.$vuetify.lang.current === 'fr' ? _vm.contactUsNote.Fr : _vm.contactUsNote.En
        )}},[_vm._v(_vm._s(_vm.$t("components.contact.fill")))]):_c('v-card-title',{class:`
                    text-subtitle-2 text-sm-subtitle-1
                    font-weight-medium pt-0`},[_vm._v(_vm._s(_vm.$t("components.contact.fill")))]),_c('v-card-text',{staticClass:"py-0"},[_c('no-autocomplete-textfield',{attrs:{"outlined":"","label":_vm.$t('components.contact.subject'),"clearable":"","rules":[_vm.$rules.required]},model:{value:(_vm.message.subject),callback:function ($$v) {_vm.$set(_vm.message, "subject", $$v)},expression:"message.subject"}}),_c('v-textarea',{attrs:{"rows":"8","outlined":"","label":_vm.$t('components.contact.message'),"clearable":"","rules":[_vm.$rules.required]},model:{value:(_vm.message.text),callback:function ($$v) {_vm.$set(_vm.message, "text", $$v)},expression:"message.text"}})],1),_c('v-card-actions',{staticClass:"justify-end pt-0 pr-4"},[_c('v-col',{staticClass:"pa-0"},[_c('v-checkbox',{attrs:{"label":_vm.$t('components.contact.send_me_a_copy')},model:{value:(_vm.sendCopy),callback:function ($$v) {_vm.sendCopy=$$v},expression:"sendCopy"}})],1),_c('v-btn',{staticClass:"primary",attrs:{"rules":[_vm.$rules.required],"disabled":!_vm.valid,"loading":_vm.sending},on:{"click":_vm.sendMessageToAdmin}},[_vm._v(_vm._s(_vm.$t("globals.submit")))])],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t("components.contact.success"))+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.$t("globals.close"))+" ")])],1),_c('error-handler',{attrs:{"error":_vm.error}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }